import React from "react"

import {Container, Form, Row, Col} from "react-bootstrap"
import Layout from "../../components/layout_en"
import validator from 'email-validator'
import CONFIG from '../../global-vars-trezo.js'
import Seo from "../../components/seo"
import checked from "../../images/checked.mp4";
import bubble from "../../images/contact-us.svg";




class MasterForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      email:  '',
      phone:'',
      company:'',
      name: '',
      surname: '',
      message:'',
      errors:{},
    }
  }


  handleChange = event => {
    const {name, value} = event.target

    this.setState({
      [name]: value
    })



  }

  handleSubmit = event => {
    event.preventDefault()


    var xhr = new XMLHttpRequest();
    var url = `${CONFIG.HUB_FORM_ROOT}${CONFIG.HUB_CONTACT_FORM}`
    // const { email, phone, company, username} = this.state.form


    var data = {
      "fields": [
        {
          "name": "email",
          "value": this.state.email
        },
        {
          "name": "phone",
          "value": this.state.phone
        },
        {
          "name": "lastname",
          "value": this.state.name
        },
        {
          "name": "firstname",
          "value": this.state.surname
        },
        {
          "name": "message",
          "value": this.state.message
        },
        {
          "name": "origine_formulaire",
          "value": "Formulaire contact"
        }
      ],
      "context": {
        "pageUri": "www.trezorino.com",
        "pageName": "Formulaire contact"
      },
      "legalConsentOptions": {
        "consent": {
          "consentToProcess": true,
          "text": "I agree to allow Example Company to store and process my personal data.",
          "communications": [
            {
              "value": true,
              "subscriptionTypeId": 999,
              "text": "I agree to receive marketing communications from Example Company."
            }
          ]
        }
      },
      "skipValidation":true
    }
    // get our new errors
    const newErrors = this.findContactFormErrors()

    console.log("ERROR="+newErrors);

    // Conditional logic:
    if ( Object.keys(newErrors).length > 0 ) {

      // We got errors!
      this.setState({
        errors: newErrors,
      })
    } else {

      if (this.state.company){

        console.log("c'est un bot bondieu!!!!")


      } else {


        //console.log(data)

        this.setState({
          currentStep: 2,
        })

        document.getElementById("message-header").style.visibility = "hidden";


        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4 && xhr.status === 200) {
            //handleShow()
            //updateContent()
            //alert(xhr.responseText); // Returns a 200 response if the submission is successful.
          } else if (xhr.readyState === 4 && xhr.status === 403) {
            alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
          } else if (xhr.readyState === 4 && xhr.status === 404) {
            alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
          }
        }
        // Sends the request
        xhr.send(final_data)

      }

    }

  }

  /*
  * the functions for our button
  */

  submitButton(){
    let currentStep = this.state.currentStep;
    if(currentStep === 1){
      return (
          <Row className="contact-submit">
            <button
                className="btn contact-submit-btn"
                type="button" onClick={this.handleSubmit}>
              Submit
            </button>
          </Row>
      )
    }

    return null;
  }

  findContactFormErrors(){
    //const { email, phone, company} = this.state.form
    const newErrors = {}

    //mail errors
    if ( !this.state.email || this.state.email === '' ) newErrors.email = 'Please fill in this required field.'
    else if ( validator.validate(this.state.email) === false ) newErrors.email = 'Please enter a valid email'

    // phone errors
    if ( !this.state.phone || this.state.phone === '' ) newErrors.phone = 'Please fill in this required field.'
    else if ( this.state.phone.length !== 10 ) newErrors.phone = 'Please enter your 10-digit phone number'

    // message errors
    if ( this.state.message.length > 500 ) newErrors.message = 'Your message is too long'


    return newErrors
  }


  render() {
    return (
        <React.Fragment>

          <Col>

            <h1 id="message-header" className="center title">Send us a message</h1>

            <form onSubmit={this.handleSubmit}>
              {/*
                        render the form steps and pass required props in
                    */}
              <Step1
                  currentStep={this.state.currentStep}
                  handleChange={this.handleChange}
                  name={this.state.name}
                  surname={this.state.surname}
                  email={this.state.email}
                  phone={this.state.phone}
                  company={this.state.company}
                  errors={this.state.errors}
              />
              <Step2
                  currentStep={this.state.currentStep}

              />

              <Row style={{paddingLeft:"0px", paddingRight:"0px"}} xs={12} sm={12} md={12} lg={12} xl={12}>
                {this.submitButton()}
              </Row>



            </form>
          </Col>


        </React.Fragment>
    );
  }
}

function Step1(props) {

  if (props.currentStep !== 1) {
    return null
  }


  return(

      <div className="contact-form-step1">
        <Form.Group   className="contact-company">
          <Form.Label>Company*</Form.Label>
          <Form.Control className="price-fld" autoComplete="off"  autoCorrect="off" spellCheck="off" name="company"  required placeholder="Le nom de votre société" type='text' onChange={props.handleChange} isInvalid={!!props.errors.company}/>
          <Form.Control.Feedback type='invalid'>{ props.errors.company } </Form.Control.Feedback>
        </Form.Group>
        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Form.Group  className="contact-email">
              <Form.Label>E-mail*</Form.Label>
              <Form.Control className="price-fld" autoComplete="off" autoCorrect="off" spellCheck="off" name="email" required placeholder="Votre adresse e-mail" type='email'  onChange={props.handleChange} isInvalid={!!props.errors.email}/>
              <Form.Control.Feedback type='invalid'>{ props.errors.email }</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Form.Group   className="contact-phone">
              <Form.Label>Phone*</Form.Label>
              <Form.Control className="price-fld" autoComplete="off"  pattern="[789][0-9]{9}" autoCorrect="off" spellCheck="off" name="phone"  required aria-valuemin="10" placeholder="Votre numéro de téléphone" type='text' onChange={props.handleChange} isInvalid={!!props.errors.phone}/>
              <Form.Control.Feedback type='invalid'>{props.errors.phone}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Form.Group  className="contact-name">
              <Form.Label>First name</Form.Label>
              <Form.Control className="price-fld" autoComplete="off" autoCorrect="off" spellCheck="off" name="name" placeholder="Prénom" type='text'  onChange={props.handleChange} isInvalid={!!props.errors.name}/>
              <Form.Control.Feedback type='invalid'>{ props.errors.name }</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6} xl={6}>
            <Form.Group   className="contact-surname">
              <Form.Label>Name</Form.Label>
              <Form.Control className="price-fld" autoComplete="off" autoCorrect="off" spellCheck="off" name="surname"  placeholder="Nom" type='text' onChange={props.handleChange} isInvalid={!!props.errors.surname}/>
              <Form.Control.Feedback type='invalid'>{props.errors.surname}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form.Group  className="contact-message">
            <Form.Label>Message</Form.Label>
            <Form.Control name="message" as='textarea' rows={5} onChange={props.handleChange} isInvalid={ !!props.errors.message }/>
            <Form.Control.Feedback type='invalid'>{ props.errors.message }</Form.Control.Feedback>
          </Form.Group>
        </Row>
      </div>

  );
}

function Step2(props) {
  if (props.currentStep !== 2) {
    return null
  }


  return(
      <div style={{height: "600px"}}>

        <Row className="mt-5">
          <Col  xs={4} sm={4} md={3} lg={3} xl={3} className="text-end">
            <video src={checked} width="100px" height="100px"  muted playsInline autoPlay></video>
          </Col>
          <Col xs={9} sm={9} md={9} lg={9} xl={9}  className="start">
            <p style={{fontSize:"24px", fontWeight:"500", marginLeft:"10px", marginTop:"auto", marginBottom:"auto"}}>Thank you for your message.</p>
            <p style={{fontSize:"24px", fontWeight:"500", marginLeft:"10px", marginTop:"auto", marginBottom:"auto"}}>One of our consultants will contact you!</p>
          </Col>
        </Row>
      </div>
  )
}


const Contact = () => {
  return(
      <Layout>

        <Seo
            titleTemplate={`Contact Us | Trezorino`}
            image={bubble}
            title="Contact us to Explore Treasury Management Possibilities."
            description="Ready to Begin? Contact Us for a Free Demo...Our Team Is at Your Service!"
            lang="en"
        />

        <Container>
          <Row>
            <Col xs={0} sm={0} lg={3} xl={3} md={3}></Col>
            <Col className="price-box" xs={12} sm={12} lg={6} xl={6} md={6}>
              <MasterForm />
            </Col>
            <Col xs={0} sm={0} lg={3} xl={3} md={3}></Col>
          </Row>
        </Container >
      </Layout>
  )
}

export default Contact;
